import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RedirectIfToken from '../../HOC/RedirectIfToken';
import Logo from '../../assets/icons/Logo';
import useWindowWidth from 'hooks/useWindowWidth';
import useIntercom from 'hooks/useIntercom';

export default function AuthLayout({ left, center, right }) {
  const width = useWindowWidth();
  const { bootInAuthLayout } = useIntercom();
  const [SliderComponent, setSliderComponent] = useState(null);
  const location = window?.location?.pathname;

  useLayoutEffect(() => {
    const loadComponent = async () => {
      if (width > 1024) {
        const Slider = (await import('../Onboarding/Slider')).default;
        setSliderComponent(() => Slider);
      }
    };
    bootInAuthLayout();
    loadComponent();
  }, [width]);

  return (
    <RedirectIfToken>
      <div className="flex p-[16px] sm:p-6 relative overflow-y-auto min-h-screen">
        {left ? SliderComponent && <SliderComponent /> : ''}
        {center}
        {right && (
          <section className="w-full lg:my-right-layout lg:absolute lg:right-4 lg:px-64 xl:pl-112">
            {!location.match('/guest') && (
              <div className="block lg:hidden">
                <Logo />
              </div>
            )}
            <div className="overflow-y-auto overflow-x-hidden">{right}</div>
          </section>
        )}
      </div>
    </RedirectIfToken>
  );
}

AuthLayout.propTypes = {
  left: PropTypes.bool,
  center: PropTypes.element,
  right: PropTypes.element,
};
