import { useAppDispatch } from './useAppDispatch';
import { unwrapResult } from '@reduxjs/toolkit';
import { notify } from '../store/modules/global';
import useHandleError from './useHandleError';
import { handleMixPanel } from 'constants/makeRequestData';

export default function useMakeRequest() {
  let dispatch = useAppDispatch();
  const { handleError } = useHandleError();

  const makeDispatch = ({ type, action, callback, alert = true, successMessage }) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((res) => {
        if ([200, 201].includes(res?.code)) {
          handleMixPanel(type);
          if (alert) dispatch(notify({ display: true, status: 'success', message: successMessage || res?.message }));
          if (callback) callback();
        }
        if (![200, 201].includes(res?.code)) {
          if (res?.message === 'Bvn not found') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'BVN not found' }),
              }),
            );
          } else if (res?.message === 'nin not found') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'NIN not found' }),
              }),
            );
          } else if (res?.message === 'vnin not found') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'VNIN not found' }),
              }),
            );
          } else if (res?.message === 'vnin: Invalid vnin supplied, ') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'VNIN: Invalid VNIN supplied' }),
              }),
            );
          } else if (res?.message === 'nin: Invalid NIN supplied, ') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'NIN: Invalid NIN not supplied' }),
              }),
            );
          } else if (res?.message === 'Voter id not found') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'Voter ID not found' }),
              }),
            );
          } else if (res?.message === 'Invalid email/password') {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: 'Invalid Password' }),
              }),
            );
          } else {
            dispatch(
              notify({
                display: true,
                status: 'error',
                message: handleError({ status: res?.code, message: res?.message }),
              }),
            );
          }
        }
        if (!res?.code) dispatch(notify({ display: true, status: 'error', message: 'Internal Server Error' }));
        return res;
      })
      .catch((error) => error);
  };

  return { makeDispatch };
}
