import { INTERCOM_ID } from 'constants';
import { useSelector } from 'react-redux';

export default function useIntercom() {
  const { profile } = useSelector((state) => state.user);

  const injectScript = () => {
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  };

  const appUrl = process.env.NEXT_PUBLIC_INTERCOM_URL;

  const boot = () => {
    injectScript();
    window.Intercom('boot', {
      api_base: appUrl,
      app_id: INTERCOM_ID,
      name: `${profile?.first_name} ${profile?.last_name}`, // Full name
      email: `${profile?.email}`, // Email address
      created_at: Math.floor(new Date().getTime() / 1000), // Signup date as a Unix timestamp
    });
  };

  const bootInAuthLayout = () => {
    injectScript();
    window.Intercom('boot', {
      api_base: appUrl,
      app_id: INTERCOM_ID,
      name: 'there', // Full name
      email: `${profile?.email}`, // Email address
      created_at: Math.floor(new Date().getTime() / 1000), // Signup date as a Unix timestamp
    });
  };

  const show = () => {
    window.Intercom('update', {
      api_base: appUrl,
      app_id: INTERCOM_ID,
      name: `${profile?.first_name} ${profile?.last_name}`, // Full name
      email: `${profile?.email}`, // Email address
      created_at: Math.floor(new Date().getTime() / 1000), // Signup date as a Unix timestamp
    });
    window.Intercom('show');
  };

  const shutdown = () => {
    window.Intercom('shutdown');
  };

  const showHelpSupport = () => {
    const userIdentifier = profile?.id.toString();
  };

  return { boot, bootInAuthLayout, show, shutdown, showHelpSupport };
}
