import React, { useEffect, useState } from "react";
import Logo from "../../assets/icons/Logo"
import cards from "../../assets/images/cards.svg"
import services from "../../assets/images/services.svg"
import transactions from "../../assets/images/transactions.svg"

export default function Slider() {
    const [current, setCurrent] = useState(0)
    let messages = [
        {
            title: "Your one stop for all things payments.",
            caption: "Service your bills, transfer money to friends, top-up your phone and many more have never been easier and faster.",
            image: services,
            id: 0
        },
        {
            title: "Process managing your expenses with us.",
            caption: `Service your bills, transfer money to friends, top-up your phone and many more have never been easier and faster.`,
            image: transactions,
            id: 1
        },
        {
            title: "End managing your expenses with us.",
            caption: `Service your bills, transfer money to friends, top-up your phone and many more have never been easier and faster.`,
            image: services,
            id: 2
        },
        {
            title: "Pay for Subscriptions, Shop Anywhere in the world",
            caption: `With our virtual USD card, you have the world at your fingertips, shop, pay bills and subscriptions on over
             5,000 websites around the world.`,
            image: cards,
            id: 3
        }
    ]

    useEffect(() => {
        let position = 0
        const element = document.getElementById('slider')

        let timer = setInterval(() => {
            if ((current + 1) < messages.length) {
                position = (current + 1) * 100
                setCurrent(prevState => prevState + 1)
            }
            if ((current + 1) === messages.length) {
                position = 0
                setCurrent(0)
            }
            element.style.transform = `translateX(-${position}%)`
        },5000)

        return () => clearInterval(timer)
    },[current])

    return(
        <section 
            className="hidden bg-purple_transparent sticky rounded-2xl py-32 shrink-0 overflow-hidden h-[94vh] top-0 lg:flex lg:flex-col lg:flex-[.4]"
        >
            <div className="h-full">
                <div className="px-40">
                    <Logo />
                </div>
                <div className="flex mt-[3rem] 2xl:mt-[6rem] transition ease-in-out duration-1000" id="slider">
                    {
                        messages.map((message) => 
                        <div className="w-full shrink-0 px-40" key={message.id}>
                            <h2 className="text-40 font-altone_sb leading-48 mb-2">{ message.title }</h2>
                            <p className="text-16 font-dmsans_r font-normal leading-24 text-grey mb-[2rem]">{ message.caption }</p>
                            <div className="flex w-full items-center">
                                <img src={message.image} className="flex-1" alt="services" />
                            </div>
                        </div>
                        )
                    }
                </div>
            </div>

            <div className="flex justify-around px-40">
                {
                    messages.map((item) => 
                    <svg
                        viewBox="0 0 135 5" 
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg" 
                        key={item?.id}
                        className={item?.id !== 3 ? "mr-2" : ""}
                    >
                        <rect width="100%" height="5" rx="2" fill={item?.id === current ? "#6463FD" : "#E9E6FD"} />
                    </svg>
                    )
                }
            </div>
        </section>
    )
}